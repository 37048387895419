import React, { useRef, useState, useEffect, useMemo, useLayoutEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import * as THREE from 'three'
import useMainStore from './app/mainStore'
export default function Model({ index, ...props }) {
  const group = useRef()
  const { nodes, materials, animations, scene } = useGLTF('/16x4.glb')
  const { actions } = useAnimations(animations, group)

  const { updateEntitySequence, entities, updateCameraDistance, isPlaying } = useMainStore()
  const [stepSaved, setStepSaved] = useState(0)
  const [hovered, setHovered] = useState(false)
  const element = entities[index]

  const mainColor = 0x888888,
    activeColor = 0x0088ff,
    inactiveColor = 0xffaaaa,
    stepMainColor = 0xcccccc,
    stepActiveColor = 0x00aaff,
    stepInactiveColor = 0xff33ff,
    indicateMainColor = 0xffff44,
    indicateActiveColor = 0xffff88

  useFrame((state) => {
    const cameraDistance = state.camera.position.distanceTo(group.current.position)
    const v3 = group.current.position.clone()
    const worldToLocal = state.camera.worldToLocal(v3)
    const newNameObj = { cameraDistance: cameraDistance, index: index, worldToLocal: worldToLocal.x }
    if (isPlaying) {
      group.current.rotation.y += 0.005
    }

    updateCameraDistance(newNameObj)
  })

  useEffect(() => void (document.body.style.cursor = hovered ? 'pointer' : 'auto'), [hovered])

  useEffect(() => {
    let newStep = element.step
    newStep = newStep
    for (let j = 0; j < 4; j++) {
      if (element.sequence[stepSaved][j] === 0 && element.goalSequence[stepSaved][j] !== 2) {
        scene?.children[stepSaved]?.children[j]?.material.color.setHex(mainColor)
      } else if (element.sequence[stepSaved][j] === 1 && element.goalSequence[stepSaved][j] === 2) {
        scene?.children[stepSaved]?.children[j]?.material.color.setHex(activeColor)
      } else if (element.sequence[stepSaved][j] === 1 && element.goalSequence[stepSaved][j] !== 2) {
        scene?.children[stepSaved]?.children[j]?.material.color.setHex(inactiveColor)
      } else if (element.goalSequence[stepSaved][j] === 2 && element.sequence[stepSaved][j] !== 1) {
        scene?.children[stepSaved]?.children[j]?.material.color.setHex(indicateMainColor)
      }
    }
    for (let sj = 0; sj < 4; sj++) {
      if (element.sequence[newStep][sj] === 0 && element.goalSequence[newStep][sj] !== 2) {
        scene?.children[newStep]?.children[sj]?.material.color.setHex(stepMainColor)
      } else if (element.sequence[newStep][sj] === 1 && element.goalSequence[newStep][sj] === 2) {
        scene?.children[newStep]?.children[sj]?.material.color.setHex(stepActiveColor)
        const animString = `step${newStep + 1}key${sj + 1}`
        actions[animString]?.reset().setEffectiveTimeScale(4.0).setLoop(0, 0).play()
      } else if (element.sequence[newStep][sj] === 1 && element.goalSequence[newStep][sj] !== 2) {
        scene?.children[newStep]?.children[sj]?.material.color.setHex(stepInactiveColor)
      } else if (element.goalSequence[newStep][sj] === 2 && element.sequence[newStep][sj] !== 1) {
        scene?.children[newStep]?.children[sj]?.material.color.setHex(indicateActiveColor)
        //const animString = `step${newStep + 1}key${sj + 1}`
        //actions[animString]?.reset().setEffectiveTimeScale(4.0).setLoop(0, 0).play()
      }
    }
    setStepSaved(newStep)
  }, [element.step])

  useEffect(() => {
    for (let i = 0; i < 16; i++) {
      let ci = i
      for (let j = 0; j < 4; j++) {
        if (element?.sequence[i][j] === 0 && element?.goalSequence[i][j] !== 2) {
          scene?.children[ci]?.children[j].material.color.setHex(mainColor)
        } else if (element?.sequence[i][j] === 1 && element?.goalSequence[i][j] === 2) {
          scene?.children[ci]?.children[j].material.color.setHex(activeColor)
        } else if (element?.sequence[i][j] === 1 && element?.goalSequence[i][j] !== 2) {
          scene?.children[ci]?.children[j].material.color.setHex(inactiveColor)
        } else if (element?.goalSequence[i][j] === 2) {
          scene?.children[ci]?.children[j].material.color.setHex(indicateMainColor)
        }
      }
    }
    return () => {
      //
    }
  }, [element.sequence, element.goalSequence])

  const handlePointerOver = (e) => {
    e.object.material.emissive.setHex(0x888888)
    setHovered(true)
  }
  const handlePointerOut = (e) => {
    e.object.material.emissive.setHex(0x000000)
    setHovered(false)
  }

  const handlePointerDown = (e) => {
    e.stopPropagation()
    //const color = e.object.material.color.clone()
    const newNameObj = { name: e.object.name, index: index }
    updateEntitySequence(newNameObj)
    //updateBroacastData(newNameObj)
    //const color = e.object.material.color.clone()
    //updateColors({ name: e.object.name, color: color, index: index })
  }

  const handlePointerUp = (e) => {
    e.object.material.emissive.setHex(0x000000)
    e.stopPropagation()
    //dispatch(updatePointerUp({ index: index }))
  }

  const handleClick = (e) => {
    // e.stopPropagation()
    // console.log(' e.object.name ', e.object.name)
    // dispatch(updateSequence({ name: e.object.name, index: index }))
  }

  useMemo(
    () =>
      scene.traverse(
        (o) =>
          o.type === 'Mesh' &&
          ((o.material = o.material.clone()),
          //o.material.color.setHex(0xaadb85),
          (o.material.envMapIntensity = 0.99),
          (o.material.roughness = 0.0),
          (o.material.metalness = 1.0),
          // (o.material.transparent = true),
          // (o.material.opacity = 0.9),
          (o.material.emissive = new THREE.Color(0x000000))),
      ),
    [],
  )

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="step1" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh
            name="key1"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key1.geometry}
            material={nodes.key1.material}
            position={[40.69, 91.6, -109.8]}
            rotation={[0.73, -0.4, 1.16]}
            scale={[150.6, 112.14, 63.15]}
          />
          <mesh
            name="key2"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key2.geometry}
            material={nodes.key2.material}
            position={[74.12, 101.13, -51.44]}
            rotation={[1.1, -0.59, 1.23]}
            scale={[230.98, 177.37, 90.65]}
          />
          <mesh
            name="key3"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key3.geometry}
            material={nodes.key3.material}
            position={[86.62, 74.31, 30.2]}
            rotation={[1.96, -0.84, 0.25]}
            scale={[209.97, 252.54, 123.94]}
          />
          <mesh
            name="key4"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key4.geometry}
            material={nodes.key4.material}
            position={[91.66, 49.43, 103.67]}
            rotation={[2.7, -0.67, 0.95]}
            scale={[114.79, 166.4, 74.08]}
          />
        </group>
        <group name="step2" rotation={[Math.PI / 2, 0, Math.PI / 8]} scale={0.01}>
          <mesh
            name="key1001"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key1001.geometry}
            material={nodes.key1001.material}
            position={[40.69, 91.6, -109.8]}
            rotation={[0.73, -0.4, 1.16]}
            scale={[150.6, 112.14, 63.15]}
          />
          <mesh
            name="key2001"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key2001.geometry}
            material={nodes.key2001.material}
            position={[74.12, 101.13, -51.44]}
            rotation={[1.1, -0.59, 1.23]}
            scale={[230.98, 177.37, 90.65]}
          />
          <mesh
            name="key3001"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key3001.geometry}
            material={nodes.key3001.material}
            position={[86.62, 74.31, 30.2]}
            rotation={[1.96, -0.84, 0.25]}
            scale={[209.97, 252.54, 123.94]}
          />
          <mesh
            name="key4001"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key4001.geometry}
            material={nodes.key4001.material}
            position={[91.66, 49.43, 103.67]}
            rotation={[2.7, -0.67, 0.95]}
            scale={[114.79, 166.4, 74.08]}
          />
        </group>
        <group name="step3" rotation={[Math.PI / 2, 0, Math.PI / 4]} scale={0.01}>
          <mesh
            name="key1002"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key1002.geometry}
            material={nodes.key1002.material}
            position={[40.69, 91.6, -109.8]}
            rotation={[0.73, -0.4, 1.16]}
            scale={[150.6, 112.14, 63.15]}
          />
          <mesh
            name="key2002"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key2002.geometry}
            material={nodes.key2002.material}
            position={[74.12, 101.13, -51.44]}
            rotation={[1.1, -0.59, 1.23]}
            scale={[230.98, 177.37, 90.65]}
          />
          <mesh
            name="key3002"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key3002.geometry}
            material={nodes.key3002.material}
            position={[86.62, 74.31, 30.2]}
            rotation={[1.96, -0.84, 0.25]}
            scale={[209.97, 252.54, 123.94]}
          />
          <mesh
            name="key4002"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key4002.geometry}
            material={nodes.key4002.material}
            position={[91.66, 49.43, 103.67]}
            rotation={[2.7, -0.67, 0.95]}
            scale={[114.79, 166.4, 74.08]}
          />
        </group>
        <group name="step4" rotation={[Math.PI / 2, 0, 1.18]} scale={0.01}>
          <mesh
            name="key1003"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key1003.geometry}
            material={nodes.key1003.material}
            position={[40.69, 91.6, -109.8]}
            rotation={[0.73, -0.4, 1.16]}
            scale={[150.6, 112.14, 63.15]}
          />
          <mesh
            name="key2003"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key2003.geometry}
            material={nodes.key2003.material}
            position={[74.12, 101.13, -51.44]}
            rotation={[1.1, -0.59, 1.23]}
            scale={[230.98, 177.37, 90.65]}
          />
          <mesh
            name="key3003"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key3003.geometry}
            material={nodes.key3003.material}
            position={[86.62, 74.31, 30.2]}
            rotation={[1.96, -0.84, 0.25]}
            scale={[209.97, 252.54, 123.94]}
          />
          <mesh
            name="key4003"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key4003.geometry}
            material={nodes.key4003.material}
            position={[91.66, 49.43, 103.67]}
            rotation={[2.7, -0.67, 0.95]}
            scale={[114.79, 166.4, 74.08]}
          />
        </group>
        <group name="step5" rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={0.01}>
          <mesh
            name="key1004"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key1004.geometry}
            material={nodes.key1004.material}
            position={[40.69, 91.6, -109.8]}
            rotation={[0.73, -0.4, 1.16]}
            scale={[150.6, 112.14, 63.15]}
          />
          <mesh
            name="key2004"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key2004.geometry}
            material={nodes.key2004.material}
            position={[74.12, 101.13, -51.44]}
            rotation={[1.1, -0.59, 1.23]}
            scale={[230.98, 177.37, 90.65]}
          />
          <mesh
            name="key3004"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key3004.geometry}
            material={nodes.key3004.material}
            position={[86.62, 74.31, 30.2]}
            rotation={[1.96, -0.84, 0.25]}
            scale={[209.97, 252.54, 123.94]}
          />
          <mesh
            name="key4004"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key4004.geometry}
            material={nodes.key4004.material}
            position={[91.66, 49.43, 103.67]}
            rotation={[2.7, -0.67, 0.95]}
            scale={[114.79, 166.4, 74.08]}
          />
        </group>
        <group name="step6" rotation={[Math.PI / 2, 0, 1.96]} scale={0.01}>
          <mesh
            name="key1005"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key1005.geometry}
            material={nodes.key1005.material}
            position={[40.69, 91.6, -109.8]}
            rotation={[0.73, -0.4, 1.16]}
            scale={[150.6, 112.14, 63.15]}
          />
          <mesh
            name="key2005"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key2005.geometry}
            material={nodes.key2005.material}
            position={[74.12, 101.13, -51.44]}
            rotation={[1.1, -0.59, 1.23]}
            scale={[230.98, 177.37, 90.65]}
          />
          <mesh
            name="key3005"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key3005.geometry}
            material={nodes.key3005.material}
            position={[86.62, 74.31, 30.2]}
            rotation={[1.96, -0.84, 0.25]}
            scale={[209.97, 252.54, 123.94]}
          />
          <mesh
            name="key4005"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key4005.geometry}
            material={nodes.key4005.material}
            position={[91.66, 49.43, 103.67]}
            rotation={[2.7, -0.67, 0.95]}
            scale={[114.79, 166.4, 74.08]}
          />
        </group>
        <group name="step7" rotation={[Math.PI / 2, 0, 2.36]} scale={0.01}>
          <mesh
            name="key1006"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key1006.geometry}
            material={nodes.key1006.material}
            position={[40.69, 91.6, -109.8]}
            rotation={[0.73, -0.4, 1.16]}
            scale={[150.6, 112.14, 63.15]}
          />
          <mesh
            name="key2006"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key2006.geometry}
            material={nodes.key2006.material}
            position={[74.12, 101.13, -51.44]}
            rotation={[1.1, -0.59, 1.23]}
            scale={[230.98, 177.37, 90.65]}
          />
          <mesh
            name="key3006"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key3006.geometry}
            material={nodes.key3006.material}
            position={[86.62, 74.31, 30.2]}
            rotation={[1.96, -0.84, 0.25]}
            scale={[209.97, 252.54, 123.94]}
          />
          <mesh
            name="key4006"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key4006.geometry}
            material={nodes.key4006.material}
            position={[91.66, 49.43, 103.67]}
            rotation={[2.7, -0.67, 0.95]}
            scale={[114.79, 166.4, 74.08]}
          />
        </group>
        <group name="step8" rotation={[Math.PI / 2, 0, 2.75]} scale={0.01}>
          <mesh
            name="key1007"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key1007.geometry}
            material={nodes.key1007.material}
            position={[40.69, 91.6, -109.8]}
            rotation={[0.73, -0.4, 1.16]}
            scale={[150.6, 112.14, 63.15]}
          />
          <mesh
            name="key2007"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key2007.geometry}
            material={nodes.key2007.material}
            position={[74.12, 101.13, -51.44]}
            rotation={[1.1, -0.59, 1.23]}
            scale={[230.98, 177.37, 90.65]}
          />
          <mesh
            name="key3007"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key3007.geometry}
            material={nodes.key3007.material}
            position={[86.62, 74.31, 30.2]}
            rotation={[1.96, -0.84, 0.25]}
            scale={[209.97, 252.54, 123.94]}
          />
          <mesh
            name="key4007"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key4007.geometry}
            material={nodes.key4007.material}
            position={[91.66, 49.43, 103.67]}
            rotation={[2.7, -0.67, 0.95]}
            scale={[114.79, 166.4, 74.08]}
          />
        </group>
        <group name="step9" rotation={[Math.PI / 2, 0, -Math.PI]} scale={0.01}>
          <mesh
            name="key1008"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key1008.geometry}
            material={nodes.key1008.material}
            position={[40.69, 91.6, -109.8]}
            rotation={[0.73, -0.4, 1.16]}
            scale={[150.6, 112.14, 63.15]}
          />
          <mesh
            name="key2008"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key2008.geometry}
            material={nodes.key2008.material}
            position={[74.12, 101.13, -51.44]}
            rotation={[1.1, -0.59, 1.23]}
            scale={[230.98, 177.37, 90.65]}
          />
          <mesh
            name="key3008"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key3008.geometry}
            material={nodes.key3008.material}
            position={[86.62, 74.31, 30.2]}
            rotation={[1.96, -0.84, 0.25]}
            scale={[209.97, 252.54, 123.94]}
          />
          <mesh
            name="key4008"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key4008.geometry}
            material={nodes.key4008.material}
            position={[91.66, 49.43, 103.67]}
            rotation={[2.7, -0.67, 0.95]}
            scale={[114.79, 166.4, 74.08]}
          />
        </group>
        <group name="step10" rotation={[Math.PI / 2, 0, -2.75]} scale={0.01}>
          <mesh
            name="key1009"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key1009.geometry}
            material={nodes.key1009.material}
            position={[40.69, 91.6, -109.8]}
            rotation={[0.73, -0.4, 1.16]}
            scale={[150.6, 112.14, 63.15]}
          />
          <mesh
            name="key2009"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key2009.geometry}
            material={nodes.key2009.material}
            position={[74.12, 101.13, -51.44]}
            rotation={[1.1, -0.59, 1.23]}
            scale={[230.98, 177.37, 90.65]}
          />
          <mesh
            name="key3009"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key3009.geometry}
            material={nodes.key3009.material}
            position={[86.62, 74.31, 30.2]}
            rotation={[1.96, -0.84, 0.25]}
            scale={[209.97, 252.54, 123.94]}
          />
          <mesh
            name="key4009"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key4009.geometry}
            material={nodes.key4009.material}
            position={[91.66, 49.43, 103.67]}
            rotation={[2.7, -0.67, 0.95]}
            scale={[114.79, 166.4, 74.08]}
          />
        </group>
        <group name="step11" rotation={[Math.PI / 2, 0, -2.36]} scale={0.01}>
          <mesh
            name="key1010"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key1010.geometry}
            material={nodes.key1010.material}
            position={[40.69, 91.6, -109.8]}
            rotation={[0.73, -0.4, 1.16]}
            scale={[150.6, 112.14, 63.15]}
          />
          <mesh
            name="key2010"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key2010.geometry}
            material={nodes.key2010.material}
            position={[74.12, 101.13, -51.44]}
            rotation={[1.1, -0.59, 1.23]}
            scale={[230.98, 177.37, 90.65]}
          />
          <mesh
            name="key3010"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key3010.geometry}
            material={nodes.key3010.material}
            position={[86.62, 74.31, 30.2]}
            rotation={[1.96, -0.84, 0.25]}
            scale={[209.97, 252.54, 123.94]}
          />
          <mesh
            name="key4010"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key4010.geometry}
            material={nodes.key4010.material}
            position={[91.66, 49.43, 103.67]}
            rotation={[2.7, -0.67, 0.95]}
            scale={[114.79, 166.4, 74.08]}
          />
        </group>
        <group name="step12" rotation={[Math.PI / 2, 0, -1.96]} scale={0.01}>
          <mesh
            name="key1011"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key1011.geometry}
            material={nodes.key1011.material}
            position={[40.69, 91.6, -109.8]}
            rotation={[0.73, -0.4, 1.16]}
            scale={[150.6, 112.14, 63.15]}
          />
          <mesh
            name="key2011"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key2011.geometry}
            material={nodes.key2011.material}
            position={[74.12, 101.13, -51.44]}
            rotation={[1.1, -0.59, 1.23]}
            scale={[230.98, 177.37, 90.65]}
          />
          <mesh
            name="key3011"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key3011.geometry}
            material={nodes.key3011.material}
            position={[86.62, 74.31, 30.2]}
            rotation={[1.96, -0.84, 0.25]}
            scale={[209.97, 252.54, 123.94]}
          />
          <mesh
            name="key4011"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key4011.geometry}
            material={nodes.key4011.material}
            position={[91.66, 49.43, 103.67]}
            rotation={[2.7, -0.67, 0.95]}
            scale={[114.79, 166.4, 74.08]}
          />
        </group>
        <group name="step13" rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={0.01}>
          <mesh
            name="key1012"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key1012.geometry}
            material={nodes.key1012.material}
            position={[40.69, 91.6, -109.8]}
            rotation={[0.73, -0.4, 1.16]}
            scale={[150.6, 112.14, 63.15]}
          />
          <mesh
            name="key2012"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key2012.geometry}
            material={nodes.key2012.material}
            position={[74.12, 101.13, -51.44]}
            rotation={[1.1, -0.59, 1.23]}
            scale={[230.98, 177.37, 90.65]}
          />
          <mesh
            name="key3012"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key3012.geometry}
            material={nodes.key3012.material}
            position={[86.62, 74.31, 30.2]}
            rotation={[1.96, -0.84, 0.25]}
            scale={[209.97, 252.54, 123.94]}
          />
          <mesh
            name="key4012"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key4012.geometry}
            material={nodes.key4012.material}
            position={[91.66, 49.43, 103.67]}
            rotation={[2.7, -0.67, 0.95]}
            scale={[114.79, 166.4, 74.08]}
          />
        </group>
        <group name="step14" rotation={[Math.PI / 2, 0, -1.18]} scale={0.01}>
          <mesh
            name="key1013"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key1013.geometry}
            material={nodes.key1013.material}
            position={[40.69, 91.6, -109.8]}
            rotation={[0.73, -0.4, 1.16]}
            scale={[150.6, 112.14, 63.15]}
          />
          <mesh
            name="key2013"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key2013.geometry}
            material={nodes.key2013.material}
            position={[74.12, 101.13, -51.44]}
            rotation={[1.1, -0.59, 1.23]}
            scale={[230.98, 177.37, 90.65]}
          />
          <mesh
            name="key3013"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key3013.geometry}
            material={nodes.key3013.material}
            position={[86.62, 74.31, 30.2]}
            rotation={[1.96, -0.84, 0.25]}
            scale={[209.97, 252.54, 123.94]}
          />
          <mesh
            name="key4013"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key4013.geometry}
            material={nodes.key4013.material}
            position={[91.66, 49.43, 103.67]}
            rotation={[2.7, -0.67, 0.95]}
            scale={[114.79, 166.4, 74.08]}
          />
        </group>
        <group name="step15" rotation={[Math.PI / 2, 0, -Math.PI / 4]} scale={0.01}>
          <mesh
            name="key1014"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key1014.geometry}
            material={nodes.key1014.material}
            position={[40.69, 91.6, -109.8]}
            rotation={[0.73, -0.4, 1.16]}
            scale={[150.6, 112.14, 63.15]}
          />
          <mesh
            name="key2014"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key2014.geometry}
            material={nodes.key2014.material}
            position={[74.12, 101.13, -51.44]}
            rotation={[1.1, -0.59, 1.23]}
            scale={[230.98, 177.37, 90.65]}
          />
          <mesh
            name="key3014"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key3014.geometry}
            material={nodes.key3014.material}
            position={[86.62, 74.31, 30.2]}
            rotation={[1.96, -0.84, 0.25]}
            scale={[209.97, 252.54, 123.94]}
          />
          <mesh
            name="key4014"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key4014.geometry}
            material={nodes.key4014.material}
            position={[91.66, 49.43, 103.67]}
            rotation={[2.7, -0.67, 0.95]}
            scale={[114.79, 166.4, 74.08]}
          />
        </group>
        <group name="step16" rotation={[Math.PI / 2, 0, -Math.PI / 8]} scale={0.01}>
          <mesh
            name="key1015"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key1015.geometry}
            material={nodes.key1015.material}
            position={[40.69, 91.6, -109.8]}
            rotation={[0.73, -0.4, 1.16]}
            scale={[150.6, 112.14, 63.15]}
          />
          <mesh
            name="key2015"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key2015.geometry}
            material={nodes.key2015.material}
            position={[74.12, 101.13, -51.44]}
            rotation={[1.1, -0.59, 1.23]}
            scale={[230.98, 177.37, 90.65]}
          />
          <mesh
            name="key3015"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key3015.geometry}
            material={nodes.key3015.material}
            position={[86.62, 74.31, 30.2]}
            rotation={[1.96, -0.84, 0.25]}
            scale={[209.97, 252.54, 123.94]}
          />
          <mesh
            name="key4015"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key4015.geometry}
            material={nodes.key4015.material}
            position={[91.66, 49.43, 103.67]}
            rotation={[2.7, -0.67, 0.95]}
            scale={[114.79, 166.4, 74.08]}
          />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/16x4.glb')
