import React, { useRef, useState, useEffect, useMemo, useLayoutEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import * as THREE from 'three'
import useMainStore from './app/mainStore'
export default function Model({ index, ...props }) {
  const group = useRef()
  const { nodes, materials, animations, scene } = useGLTF('/6x8.glb')
  const { actions } = useAnimations(animations, group)

  const { updateEntitySequence, entities, updateCameraDistance } = useMainStore()
  const [stepSaved, setStepSaved] = useState(0)
  const [hovered, setHovered] = useState(false)
  const element = entities[index]

  const mainColor = 0xaadb85,
    activeColor = 0x004485,
    stepMainColor = 0xcc88cc,
    stepActiveColor = 0x00aaff

  useFrame((state) => {
    const cameraDistance = state.camera.position.distanceTo(group.current.position)
    const v3 = group.current.position.clone()
    const worldToLocal = state.camera.worldToLocal(v3)
    const newNameObj = { cameraDistance: cameraDistance, index: index, worldToLocal: worldToLocal.x }
    updateCameraDistance(newNameObj)
  })

  useEffect(() => void (document.body.style.cursor = hovered ? 'pointer' : 'auto'), [hovered])

  useEffect(() => {
    let newStep = element.step
    newStep = newStep
    for (let j = 0; j < 8; j++) {
      if (element.sequence[stepSaved][j] === 0) {
        scene?.children[stepSaved]?.children[j]?.material.color.setHex(mainColor)
      } else if (element.sequence[stepSaved][j] === 1) {
        scene?.children[stepSaved]?.children[j]?.material.color.setHex(activeColor)
      }
    }
    for (let sj = 0; sj < 8; sj++) {
      if (element.sequence[newStep][sj] === 0) {
        scene?.children[newStep]?.children[sj]?.material.color.setHex(stepMainColor)
      } else if (element.sequence[newStep][sj] === 1) {
        scene?.children[newStep]?.children[sj]?.material.color.setHex(stepActiveColor)
        const animString = `step${newStep + 1}key${sj + 1}`
        actions[animString]?.reset().setEffectiveTimeScale(4.0).setLoop(0, 0).play()
      }
    }
    setStepSaved(newStep)
  }, [element.step])

  useEffect(() => {
    for (let i = 0; i < 6; i++) {
      let ci = i
      for (let j = 0; j < 8; j++) {
        if (element?.sequence[i][j] === 0) {
          scene?.children[ci]?.children[j].material.color.setHex(mainColor)
        } else if (element?.sequence[i][j] === 1) {
          scene?.children[ci]?.children[j].material.color.setHex(activeColor)
        }
      }
    }
    return () => {
      //
    }
  }, [element.sequence])

  const handlePointerOver = (e) => {
    e.object.material.emissive.setHex(0x888888)
    setHovered(true)
  }
  const handlePointerOut = (e) => {
    e.object.material.emissive.setHex(0x000000)
    setHovered(false)
  }

  const handlePointerDown = (e) => {
    e.stopPropagation()
    //const color = e.object.material.color.clone()
    const newNameObj = { name: e.object.name, index: index }
    updateEntitySequence(newNameObj)
    //updateBroacastData(newNameObj)
    //const color = e.object.material.color.clone()
    //updateColors({ name: e.object.name, color: color, index: index })
  }

  const handlePointerUp = (e) => {
    e.object.material.emissive.setHex(0x000000)
    e.stopPropagation()
    //dispatch(updatePointerUp({ index: index }))
  }

  const handleClick = (e) => {
    // e.stopPropagation()
    // console.log(' e.object.name ', e.object.name)
    // dispatch(updateSequence({ name: e.object.name, index: index }))
  }

  useMemo(
    () =>
      scene.traverse(
        (o) =>
          o.type === 'Mesh' &&
          ((o.material = o.material.clone()),
          //o.material.color.setHex(0xaadb85),
          (o.material.envMapIntensity = 0.99),
          (o.material.roughness = 0.0),
          (o.material.metalness = 1.0),
          // (o.material.transparent = true),
          // (o.material.opacity = 0.9),
          (o.material.emissive = new THREE.Color(0x000000))),
      ),
    [],
  )

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="step1" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh
            name="key1"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key1.geometry}
            material={nodes.key1.material}
            position={[-16.36, 21.68, -44.58]}
            rotation={[0.43, 0.37, 0.25]}
            scale={[55.76, 55.76, 35.32]}
          />
          <mesh
            name="key2"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key2.geometry}
            material={nodes.key2.material}
            position={[-4.92, 47.42, -44.24]}
            rotation={[0.82, 0.08, -0.16]}
            scale={[63.06, 63.06, 40.77]}
          />
          <mesh
            name="key3"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key3.geometry}
            material={nodes.key3.material}
            position={[22.72, 54.58, -27.89]}
            rotation={[1.11, -0.36, 1.32]}
            scale={[97.55, 97.55, 46.12]}
          />
          <mesh
            name="key4"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key4.geometry}
            material={nodes.key4.material}
            position={[42.97, 34.01, -3.81]}
            rotation={[1.49, -0.92, 1.4]}
            scale={[89.36, 89.36, 41.08]}
          />
          <mesh
            name="key5"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key5.geometry}
            material={nodes.key5.material}
            position={[61.56, 10.93, 12.92]}
            rotation={[2.55, -1.3, 0.78]}
            scale={[82.4, 82.4, 43.8]}
          />
          <mesh
            name="key6"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key6.geometry}
            material={nodes.key6.material}
            position={[56.15, -12.83, 28.26]}
            rotation={[-2.67, -1.01, 1.8]}
            scale={[67.93, 67.93, 41.03]}
          />
          <mesh
            name="key7"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key7.geometry}
            material={nodes.key7.material}
            position={[35.03, -26.25, 33.12]}
            rotation={[-2.51, -0.61, 2.04]}
            scale={[55, 55, 34.7]}
          />
          <mesh
            name="key8"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key8.geometry}
            material={nodes.key8.material}
            position={[11.46, -25.2, 37.82]}
            rotation={[-2.55, -0.24, -1.99]}
            scale={[57.64, 57.64, 31.83]}
          />
        </group>
        <group name="step2" rotation={[Math.PI / 2, 0, Math.PI / 3]} scale={0.01}>
          <mesh
            name="key1001"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key1001.geometry}
            material={nodes.key1001.material}
            position={[-16.36, 21.68, -44.58]}
            rotation={[0.43, 0.37, 0.25]}
            scale={[55.76, 55.76, 35.32]}
          />
          <mesh
            name="key2001"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key2001.geometry}
            material={nodes.key2001.material}
            position={[-4.92, 47.42, -44.24]}
            rotation={[0.82, 0.08, -0.16]}
            scale={[63.06, 63.06, 40.77]}
          />
          <mesh
            name="key3001"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key3001.geometry}
            material={nodes.key3001.material}
            position={[22.72, 54.58, -27.89]}
            rotation={[1.11, -0.36, 1.32]}
            scale={[97.55, 97.55, 46.12]}
          />
          <mesh
            name="key4001"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key4001.geometry}
            material={nodes.key4001.material}
            position={[42.97, 34.01, -3.81]}
            rotation={[1.49, -0.92, 1.4]}
            scale={[89.36, 89.36, 41.08]}
          />
          <mesh
            name="key5001"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key5001.geometry}
            material={nodes.key5001.material}
            position={[61.56, 10.93, 12.92]}
            rotation={[2.55, -1.3, 0.78]}
            scale={[82.4, 82.4, 43.8]}
          />
          <mesh
            name="key6001"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key6001.geometry}
            material={nodes.key6001.material}
            position={[56.15, -12.83, 28.26]}
            rotation={[-2.67, -1.01, 1.8]}
            scale={[67.93, 67.93, 41.03]}
          />
          <mesh
            name="key7001"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key7001.geometry}
            material={nodes.key7001.material}
            position={[35.03, -26.25, 33.12]}
            rotation={[-2.51, -0.61, 2.04]}
            scale={[55, 55, 34.7]}
          />
          <mesh
            name="key8001"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key8001.geometry}
            material={nodes.key8001.material}
            position={[11.46, -25.2, 37.82]}
            rotation={[-2.55, -0.24, -1.99]}
            scale={[57.64, 57.64, 31.83]}
          />
        </group>
        <group name="step3" rotation={[Math.PI / 2, 0, 2.09]} scale={0.01}>
          <mesh
            name="key1002"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key1002.geometry}
            material={nodes.key1002.material}
            position={[-16.36, 21.68, -44.58]}
            rotation={[0.43, 0.37, 0.25]}
            scale={[55.76, 55.76, 35.32]}
          />
          <mesh
            name="key2002"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key2002.geometry}
            material={nodes.key2002.material}
            position={[-4.92, 47.42, -44.24]}
            rotation={[0.82, 0.08, -0.16]}
            scale={[63.06, 63.06, 40.77]}
          />
          <mesh
            name="key3002"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key3002.geometry}
            material={nodes.key3002.material}
            position={[22.72, 54.58, -27.89]}
            rotation={[1.11, -0.36, 1.32]}
            scale={[97.55, 97.55, 46.12]}
          />
          <mesh
            name="key4002"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key4002.geometry}
            material={nodes.key4002.material}
            position={[42.97, 34.01, -3.81]}
            rotation={[1.49, -0.92, 1.4]}
            scale={[89.36, 89.36, 41.08]}
          />
          <mesh
            name="key5002"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key5002.geometry}
            material={nodes.key5002.material}
            position={[61.56, 10.93, 12.92]}
            rotation={[2.55, -1.3, 0.78]}
            scale={[82.4, 82.4, 43.8]}
          />
          <mesh
            name="key6002"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key6002.geometry}
            material={nodes.key6002.material}
            position={[56.15, -12.83, 28.26]}
            rotation={[-2.67, -1.01, 1.8]}
            scale={[67.93, 67.93, 41.03]}
          />
          <mesh
            name="key7002"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key7002.geometry}
            material={nodes.key7002.material}
            position={[35.03, -26.25, 33.12]}
            rotation={[-2.51, -0.61, 2.04]}
            scale={[55, 55, 34.7]}
          />
          <mesh
            name="key8002"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key8002.geometry}
            material={nodes.key8002.material}
            position={[11.46, -25.2, 37.82]}
            rotation={[-2.55, -0.24, -1.99]}
            scale={[57.64, 57.64, 31.83]}
          />
        </group>
        <group name="step4" rotation={[Math.PI / 2, 0, -Math.PI]} scale={0.01}>
          <mesh
            name="key1003"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key1003.geometry}
            material={nodes.key1003.material}
            position={[-16.36, 21.68, -44.58]}
            rotation={[0.43, 0.37, 0.25]}
            scale={[55.76, 55.76, 35.32]}
          />
          <mesh
            name="key2003"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key2003.geometry}
            material={nodes.key2003.material}
            position={[-4.92, 47.42, -44.24]}
            rotation={[0.82, 0.08, -0.16]}
            scale={[63.06, 63.06, 40.77]}
          />
          <mesh
            name="key3003"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key3003.geometry}
            material={nodes.key3003.material}
            position={[22.72, 54.58, -27.89]}
            rotation={[1.11, -0.36, 1.32]}
            scale={[97.55, 97.55, 46.12]}
          />
          <mesh
            name="key4003"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key4003.geometry}
            material={nodes.key4003.material}
            position={[42.97, 34.01, -3.81]}
            rotation={[1.49, -0.92, 1.4]}
            scale={[89.36, 89.36, 41.08]}
          />
          <mesh
            name="key5003"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key5003.geometry}
            material={nodes.key5003.material}
            position={[61.56, 10.93, 12.92]}
            rotation={[2.55, -1.3, 0.78]}
            scale={[82.4, 82.4, 43.8]}
          />
          <mesh
            name="key6003"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key6003.geometry}
            material={nodes.key6003.material}
            position={[56.15, -12.83, 28.26]}
            rotation={[-2.67, -1.01, 1.8]}
            scale={[67.93, 67.93, 41.03]}
          />
          <mesh
            name="key7003"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key7003.geometry}
            material={nodes.key7003.material}
            position={[35.03, -26.25, 33.12]}
            rotation={[-2.51, -0.61, 2.04]}
            scale={[55, 55, 34.7]}
          />
          <mesh
            name="key8003"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key8003.geometry}
            material={nodes.key8003.material}
            position={[11.46, -25.2, 37.82]}
            rotation={[-2.55, -0.24, -1.99]}
            scale={[57.64, 57.64, 31.83]}
          />
        </group>
        <group name="step5" rotation={[Math.PI / 2, 0, -2.09]} scale={0.01}>
          <mesh
            name="key1004"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key1004.geometry}
            material={nodes.key1004.material}
            position={[-16.36, 21.68, -44.58]}
            rotation={[0.43, 0.37, 0.25]}
            scale={[55.76, 55.76, 35.32]}
          />
          <mesh
            name="key2004"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key2004.geometry}
            material={nodes.key2004.material}
            position={[-4.92, 47.42, -44.24]}
            rotation={[0.82, 0.08, -0.16]}
            scale={[63.06, 63.06, 40.77]}
          />
          <mesh
            name="key3004"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key3004.geometry}
            material={nodes.key3004.material}
            position={[22.72, 54.58, -27.89]}
            rotation={[1.11, -0.36, 1.32]}
            scale={[97.55, 97.55, 46.12]}
          />
          <mesh
            name="key4004"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key4004.geometry}
            material={nodes.key4004.material}
            position={[42.97, 34.01, -3.81]}
            rotation={[1.49, -0.92, 1.4]}
            scale={[89.36, 89.36, 41.08]}
          />
          <mesh
            name="key5004"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key5004.geometry}
            material={nodes.key5004.material}
            position={[61.56, 10.93, 12.92]}
            rotation={[2.55, -1.3, 0.78]}
            scale={[82.4, 82.4, 43.8]}
          />
          <mesh
            name="key6004"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key6004.geometry}
            material={nodes.key6004.material}
            position={[56.15, -12.83, 28.26]}
            rotation={[-2.67, -1.01, 1.8]}
            scale={[67.93, 67.93, 41.03]}
          />
          <mesh
            name="key7004"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key7004.geometry}
            material={nodes.key7004.material}
            position={[35.03, -26.25, 33.12]}
            rotation={[-2.51, -0.61, 2.04]}
            scale={[55, 55, 34.7]}
          />
          <mesh
            name="key8004"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key8004.geometry}
            material={nodes.key8004.material}
            position={[11.46, -25.2, 37.82]}
            rotation={[-2.55, -0.24, -1.99]}
            scale={[57.64, 57.64, 31.83]}
          />
        </group>
        <group name="step6" rotation={[Math.PI / 2, 0, -Math.PI / 3]} scale={0.01}>
          <mesh
            name="key1005"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key1005.geometry}
            material={nodes.key1005.material}
            position={[-16.36, 21.68, -44.58]}
            rotation={[0.43, 0.37, 0.25]}
            scale={[55.76, 55.76, 35.32]}
          />
          <mesh
            name="key2005"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key2005.geometry}
            material={nodes.key2005.material}
            position={[-4.92, 47.42, -44.24]}
            rotation={[0.82, 0.08, -0.16]}
            scale={[63.06, 63.06, 40.77]}
          />
          <mesh
            name="key3005"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key3005.geometry}
            material={nodes.key3005.material}
            position={[22.72, 54.58, -27.89]}
            rotation={[1.11, -0.36, 1.32]}
            scale={[97.55, 97.55, 46.12]}
          />
          <mesh
            name="key4005"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key4005.geometry}
            material={nodes.key4005.material}
            position={[42.97, 34.01, -3.81]}
            rotation={[1.49, -0.92, 1.4]}
            scale={[89.36, 89.36, 41.08]}
          />
          <mesh
            name="key5005"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key5005.geometry}
            material={nodes.key5005.material}
            position={[61.56, 10.93, 12.92]}
            rotation={[2.55, -1.3, 0.78]}
            scale={[82.4, 82.4, 43.8]}
          />
          <mesh
            name="key6005"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key6005.geometry}
            material={nodes.key6005.material}
            position={[56.15, -12.83, 28.26]}
            rotation={[-2.67, -1.01, 1.8]}
            scale={[67.93, 67.93, 41.03]}
          />
          <mesh
            name="key7005"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key7005.geometry}
            material={nodes.key7005.material}
            position={[35.03, -26.25, 33.12]}
            rotation={[-2.51, -0.61, 2.04]}
            scale={[55, 55, 34.7]}
          />
          <mesh
            name="key8005"
            onClick={handleClick}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            geometry={nodes.key8005.geometry}
            material={nodes.key8005.material}
            position={[11.46, -25.2, 37.82]}
            rotation={[-2.55, -0.24, -1.99]}
            scale={[57.64, 57.64, 31.83]}
          />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/6x8.glb')
