import React from 'react'
import {
  Select,
  RadioGroup,
  Radio,
  Slider,
  SliderTrack,
  FormLabel,
  SliderFilledTrack,
  SliderThumb,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Box,
  Flex,
  Text,
  Button,
  Stack,
  useColorModeValue,
  useDisclosure,
  Progress,
  Switch,
} from '@chakra-ui/react'
import useMainStore from '../app/mainStore'
import AnimatedNumber from 'react-animated-number'
import AnimatedNumbers from 'react-animated-numbers'

function ScaleTypeRadio() {
  const { scaleType, chordType, key, updateKey, updateScaleType, updateChordType } = useMainStore()
  const handleSelect = (e) => {
    e.stopPropagation()
    updateKey(e.target.value)
  }

  return (
    <Stack direction="column">
      <RadioGroup p={2} onChange={(value) => updateScaleType(value)} value={scaleType}>
        <Stack direction="row">
          <Radio value="Major">Major</Radio>
          <Radio value="minor">Minor (natural)</Radio>
        </Stack>
      </RadioGroup>
      {/* <RadioGroup p={2} onChange={(value) => updateChordType(value)} value={chordType}>
        {scaleType === 'Major' && (
          <Stack direction="row">
            <Radio value="majsingle">Single Note</Radio>
            <Radio value="M">Major (Chord)</Radio>
            <Radio value="maj7">Major 7 (Chord)</Radio>
          </Stack>
        )}
        {scaleType === 'minor' && (
          <Stack direction="row">
            <Radio value="minsingle">Single Note</Radio>
            <Radio value="m">minor (Chord)</Radio>
            <Radio value="min7">minor 7 (Chord)</Radio>
          </Stack>
        )}
      </RadioGroup> */}
      <Select onChange={handleSelect} value={key}>
        <option value="C">C</option>
        <option value="C#">C#</option>
        <option value="D">D</option>
        <option value="D#">D#</option>
        <option value="E">E</option>
        <option value="E#">E#</option>
        <option value="F">F</option>
        <option value="F#">F#</option>
        <option value="G">G</option>
        <option value="A">A</option>
        <option value="A#">A#</option>
        <option value="B">B</option>
      </Select>
    </Stack>
  )
}
const NavBar = () => {
  const { isOpen, onToggle } = useDisclosure()
  const { isPlaying, bpm, entities, updateBpm, updateIsPlaying, resetEntitySequence } = useMainStore()
  const minBpm = 60,
    maxBpm = 230
  const [valueBpm, setValueBpm] = React.useState(bpm)
  function setNewValueBpm(val) {
    setValueBpm(val)
    updateBpm(val)
  }
  const handleChangeBpm = (value) => (value < minBpm ? setNewValueBpm(minBpm) : value > maxBpm ? setNewValueBpm(maxBpm) : setNewValueBpm(value))

  // BPM TAP
  // modified from https://codepen.io/NxSolari/pen/VBGGNO
  const [state, setState] = React.useState({
    count: 0,
    timeFirst: 0,
    timePrevious: 0,
    bpm: 0,
  })
  const handleMouseDown = () => {
    const { count, timeFirst, timePrevious } = state
    const timeSeconds = new Date()
    const time = timeSeconds.getTime()
    //if its been 3 seconds since last click reset the counter & previous time
    if (timePrevious !== 0 && time - timePrevious > 3000) {
      setState({
        ...state,
        count: 0,
        timePrevious: time,
      })
      return false
    }
    //if first click set the initial time and count
    if (count === 0) {
      setState({ ...state, timeFirst: time, count: count + 1 })
    } else {
      const bpmAvg = (60000 * count) / (time - timeFirst)
      let bpm2 = Math.round(bpmAvg * 100) / 100
      setState({ ...state, bpm: bpm2, count: count + 1, timePrevious: time })
      setValueBpm(bpm2)
      updateBpm(bpm2)
    }
  }
  //

  const bgColor = useColorModeValue('gray.600', 'gray.200')
  const bgColor2 = useColorModeValue('white', 'gray.800')
  const interval = 8 * 8

  return (
    <Flex flexDirection={'column'}>
      <Flex
        // bg={useColorModeValue('white', 'gray.800')}
        bg={useColorModeValue('#ffffff55', '#66666655')}
        color={useColorModeValue('gray.600', 'white')}
        p={1}
      >
        <Flex flexDirection={'column'}>
          <Button
            size={'sm'}
            ml={3}
            mt={2}
            colorScheme="teal"
            variant="outline"
            onClick={() => (resetEntitySequence({ index: 0 }), resetEntitySequence({ index: 1 }), resetEntitySequence({ index: 2 }))}
          >
            Reset
          </Button>
          <Button size={'sm'} ml={3} mt={2} colorScheme="teal" variant="outline" onClick={() => updateIsPlaying(!isPlaying)}>
            {isPlaying ? 'Stop' : 'Play'}
          </Button>
          <ScaleTypeRadio />
        </Flex>

        <Flex
          flex={1}
          m={2}
          p={2}
          border={1}
          borderRadius={6}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.900')}
          flexDirection={'column'}
        >
          <Flex flexDirection={'row'}>
            <Text flex={1} p={4}>
              {'BPM'}
            </Text>
            <Button flex={1} m={2} onMouseDown={() => handleMouseDown()}>
              Tap
            </Button>
          </Flex>
          <Flex
            bg={useColorModeValue('#ffffff55', '#66666655')}
            color={useColorModeValue('gray.600', 'white')}
            py={{ base: 2 }}
            px={{ base: 4 }}
            border={1}
            borderRadius={6}
            borderStyle={'solid'}
            borderColor={useColorModeValue('gray.200', 'gray.900')}
            p={2}
            height={'55px'}
          >
            <NumberInput min={minBpm} max={maxBpm} width="120px" mr="1rem" value={valueBpm} onChange={handleChangeBpm}>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Slider width="130px" min={minBpm} max={maxBpm} flex="1" focusThumbOnChange={false} value={valueBpm} onChange={handleChangeBpm}>
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb fontSize="sm" boxSize="32px" />
            </Slider>
          </Flex>
        </Flex>
      </Flex>
      {/* <Stack p={4} direction="row">
        <Flex flexDirection={'column'} key={entities[2].model} role={'group'} p={1} w={'full'} bg={bgColor2} boxShadow={'2xl'} rounded={'lg'} pos={'relative'}>
          <Text fontWeight="bold" textTransform="uppercase" letterSpacing="wide" color="teal.600">
            {`Step: ${entities[2].step + 1}/${entities[2].steps.length} D: ${entities[2].cameraDistance} W: ${entities[2].worldToLocal}`}
          </Text>
          <Text fontWeight="bold" textTransform="uppercase" letterSpacing="wide" color="teal.600">
            Score:
          </Text>
          <AnimatedNumbers includeComma animateToNumber={entities[2].score} configs={[{ mass: 1, tension: 220, friction: 10 }]}></AnimatedNumbers>
          <Text>{`Sequence Complete: ${entities[2].sequenceComplete}`}</Text>
          <FormLabel htmlFor="isChecked">Sequence Complete:</FormLabel>
          <Switch isChecked={entities[2].sequenceComplete} id="isChecked" />

          <Progress width={300} colorScheme="green" size="sm" value={(entities[2].tick / interval) * 100} />
        </Flex>
        {entities.map((element, index) => (
          <Box key={element.model} role={'group'} p={1} w={'full'} bg={bgColor2} boxShadow={'2xl'} rounded={'lg'} pos={'relative'}>
            <Text fontWeight="bold" textTransform="uppercase" letterSpacing="wide" color="teal.600">
              {`S: ${element.step + 1}/${element.steps.length} D: ${element.cameraDistance} W: ${element.worldToLocal}`}
            </Text>
          </Box>
        ))}
      </Stack> */}
    </Flex>
  )
}

export default NavBar
