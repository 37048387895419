import { useState, useEffect } from 'react'
import { Song, Track, Instrument } from 'reactronica'
import useMainStore from '../app/mainStore'

// const snareSample = '/snare.wav'
// const kickSample = '/kick.wav'

const SoundControl = ({ ...props }) => {
  const { isPlaying, volume, bpm, entities, updateStep } = useMainStore()
  const [samples, setSamples] = useState(null)
  const [samplesLoaded, setSamplesLoaded] = useState(false)

  useEffect(() => {
    setSamples({
      E4: '/kick1.wav',
      C4: '/snare1.wav',
      D4: '/hihat1.wav',
      F4: '/perc1.wav',
    })
  }, [])

  return (
    <>
      <Song volume={volume} isPlaying={isPlaying} bpm={bpm}>
        {entities.map((element, index) => (
          <Track
            key={element.model + index}
            volume={element.cameraDistance}
            pan={element.worldToLocal}
            subdivision={element.subdivision}
            steps={element.steps}
            onStepPlay={(stepNotes, idx) => {
              updateStep({ entity: index, step: idx, stepNotes: stepNotes })
            }}
          >
            <Instrument
              type={element.instrument}
              notes={element.notes}
              samples={samples || {}}
              // envelope={{
              //   attack: 0.2,
              //   release: 0.5,
              // }}
              onLoad={(buffers) => {
                console.log('loaded')
                console.log(buffers)
                setSamplesLoaded(true)
              }}
            />
            {/* <Effect type="distortion" wet={0.3} />
            <Effect type="autoFilter" wet={0.8} /> */}
          </Track>
        ))}
      </Song>
    </>
  )
}

export default SoundControl
