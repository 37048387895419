import create from 'zustand'
import { produce } from 'immer'
import { devtools, persist } from 'zustand/middleware'
import { Chord, ChordType, Key } from '@tonaljs/tonal'

const blankSequence = [
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
  ],
  blankGroupSequence = [0, 0, 0, 0, 0, 0, 0, 0],
  blankSteps = [null, null, null, null, null, null, null, null],
  blankSequence6 = [
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
  ],
  blankGroupSequence6 = [0, 0, 0, 0, 0, 0],
  blankSteps6 = [null, null, null, null, null, null],
  blankSequence16 = [
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
  ],
  blankGroupSequence16 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  blankSteps16 = [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null]

const song16 = [
  [
    [2, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 2, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [2, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 2, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
  ],
  [
    [0, 0, 2, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [2, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 2, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [2, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
  ],
  [
    [0, 2, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [2, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 2, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [2, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
  ],
  [
    [0, 0, 2, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 2],
    [0, 0, 0, 2],
    [0, 0, 2, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 2],
    [0, 0, 0, 2],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 2, 0, 0],
    [0, 2, 0, 0],
    [0, 2, 0, 0],
    [0, 0, 0, 2],
  ],
  [
    [2, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 2, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [2, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 2, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
  ],
  [
    [0, 0, 2, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [2, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 2, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [2, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
  ],
  [
    [0, 2, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [2, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 2, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [2, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
  ],
  [
    [0, 0, 2, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 2],
    [0, 0, 0, 2],
    [0, 0, 2, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 2],
    [0, 0, 0, 2],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 2, 0, 0],
    [0, 2, 0, 0],
    [0, 2, 0, 0],
    [0, 0, 0, 2],
  ],
]

const mainStore = (set, get) => ({
  broacastData: {},
  key: 'C',
  scaleType: 'Major',
  chordType: 'majsingle', // M, maj7, m, min7
  volume: -16,
  bpm: 85,
  isPlaying: false,
  entities: [
    {
      score: 0,
      cameraDistance: 0,
      worldToLocal: 0,
      notes: [],
      step: 0,
      steps: blankSteps,
      octave: 3,
      subdivision: '8n',
      instrument: 'polySynth',
      model: '8x8',
      sequence: blankSequence,
      songStep: 0,
      tick: 0,
      sequenceComplete: false,
      goalSequence: song16[0],
      groupSequence: blankGroupSequence,
    },
    {
      score: 0,
      cameraDistance: 0,
      worldToLocal: 0,
      notes: [],
      step: 0,
      steps: blankSteps6,
      octave: 2,
      subdivision: '8n',
      instrument: 'polySynth',
      model: '6x8',
      sequence: blankSequence6,
      songStep: 0,
      tick: 0,
      sequenceComplete: false,
      goalSequence: song16[0],
      groupSequence: blankGroupSequence6,
    },
    {
      score: 0,
      cameraDistance: 0,
      worldToLocal: 0,
      notes: [],
      step: 0,
      steps: blankSteps16,
      octave: 4,
      subdivision: '16n',
      instrument: 'sampler',
      model: '16x4',
      sequence: blankSequence16,
      songStep: 0,
      tick: 0,
      sequenceComplete: false,
      goalSequence: song16[0],
      groupSequence: blankGroupSequence16,
    },
  ],
  updateBroacastData(broacastData) {
    set((state) => ({
      broacastData: { ...broacastData },
    }))
  },
  updateCameraDistance(payload) {
    set(
      produce((state) => {
        const nw = -(payload.cameraDistance - 5.0)
        let rounded = Math.round(nw * 100 + Number.EPSILON) / 100
        state.entities[payload.index].cameraDistance = rounded

        const nwx = payload.worldToLocal / 4
        let xrounded = Math.round(nwx * 100 + Number.EPSILON) / 100
        state.entities[payload.index].worldToLocal = xrounded
      }),
    )
  },
  updateIsPlaying(isPlaying) {
    set((state) => ({
      isPlaying: isPlaying,
    }))
  },
  updateScaleType(scale) {
    console.log('scale in ', scale)
    set((state) => ({
      scaleType: scale,
    }))
  },
  updateChordType(chordType) {
    set((state) => ({
      chordType: chordType,
    }))
  },
  updateKey(key) {
    set((state) => ({
      key: key,
    }))
  },
  updateBpm(bpm) {
    set((state) => ({
      bpm: bpm,
    }))
  },
  updateStep(payload) {
    set(
      produce((state) => {
        if (payload.stepNotes.length > 0) {
          const savedseq = state.entities[payload.entity].goalSequence[payload.step].findIndex((note) => note === 2)
          const nowseq = state.entities[payload.entity].sequence[payload.step].findIndex((note) => note === 1)
          if (savedseq === nowseq && state.entities[payload.entity].sequenceComplete) {
            state.entities[payload.entity].score += 25.0
          }
          // console.log('payload.stepNotes ', payload.stepNotes)
          // console.log('savedseq ', savedseq)
          // console.log('nowseq ', nowseq)
        }

        const interval = 8 * 8 // state.entities[payload.entity].goalSequence.length
        const songLength = 4

        if (payload.entity === 2) {
          //state.entities[payload.entity].tick += 1

          if (state.entities[payload.entity].tick < interval - 1) {
            state.entities[payload.entity].tick += 1
          } else {
            if (state.entities[payload.entity].songStep < songLength - 1) {
              state.entities[payload.entity].songStep += 1
            } else {
              state.entities[payload.entity].songStep = 0
            }
            state.entities[payload.entity].goalSequence = song16[state.entities[payload.entity].songStep]
            state.entities[payload.entity].tick = 0
            state.entities[payload.entity].sequenceComplete = false

            //const lengthIs8 = state.entities[payload.entity].sequence.length === 8
            //const lengthIs16 = state.entities[payload.entity].sequence.length === 16
            //state.entities[payload.entity].sequence = lengthIs8 ? blankSequence : lengthIs16 ? blankSequence16 : blankSequence6
            //state.entities[payload.entity].groupSequence = lengthIs8 ? blankGroupSequence : lengthIs16 ? blankGroupSequence16 : blankGroupSequence6
            //state.entities[payload.entity].steps = lengthIs8 ? blankSteps : lengthIs16 ? blankSteps16 : blankSteps6
          }

          //state.entities[payload.entity].goalSequence = song16[state.entities[payload.entity].songStep]
          //console.log('entities.tick ', state.entities[payload.entity].tick)
          //console.log('entities.songStep ', state.entities[payload.entity].songStep)
        }
        state.entities[payload.entity].step = payload.step
      }),
    )
  },
  resetEntitySequence: (payload) => {
    const id = payload.index

    set(
      produce((state) => {
        const lengthIs8 = state.entities[id].sequence.length === 8
        const lengthIs16 = state.entities[id].sequence.length === 16
        state.entities[id].sequence = lengthIs8 ? blankSequence : lengthIs16 ? blankSequence16 : blankSequence6
        state.entities[id].groupSequence = lengthIs8 ? blankGroupSequence : lengthIs16 ? blankGroupSequence16 : blankGroupSequence6
        state.entities[id].steps = lengthIs8 ? blankSteps : lengthIs16 ? blankSteps16 : blankSteps6
        state.entities[id].score = 0
      }),
    )
  },
  updateEntitySequence: (payload) => {
    const id = payload.index
    const name = payload.name

    set(
      produce((state) => {
        let iv = name.substring(4),
          jv = name.substring(3, 4)
        jv = parseInt(jv) - 1

        switch (iv) {
          case '':
            iv = 0
            break
          case '001':
            iv = 1
            break
          case '002':
            iv = 2
            break
          case '003':
            iv = 3
            break
          case '004':
            iv = 4
            break
          case '005':
            iv = 5
            break
          case '006':
            iv = 6
            break
          case '007':
            iv = 7
            break
          case '008':
            iv = 8
            break
          case '009':
            iv = 9
            break
          case '010':
            iv = 10
            break
          case '011':
            iv = 11
            break
          case '012':
            iv = 12
            break
          case '013':
            iv = 13
            break
          case '014':
            iv = 14
            break
          case '015':
            iv = 15
            break
          case '016':
            iv = 16
            break
        }

        const seqlen = state.entities[id].sequence[0].length

        for (let j = 0; j < seqlen; j++) {
          state.entities[id].sequence[iv][j] =
            state.entities[id].sequence[iv][jv] !== state.entities[id].sequence[iv][j] ? 0 : state.entities[id].sequence[iv][j]
        }
        state.entities[id].sequence[iv][jv] = state.entities[id].sequence[iv][jv] === 0 ? 1 : 0

        for (let i = 0; i < state.entities[id].groupSequence.length; i++) {
          state.entities[id].groupSequence[i] =
            state.entities[id].sequence[i].findIndex((note) => note === 1) !== -1 ? state.entities[id].sequence[i].findIndex((note) => note === 1) + 1 : 0
        }

        const convertedGoalSequence = state.entities[id].goalSequence.map(function (item) {
          const subarray = item.map(function (sitem) {
            return sitem == 2 ? 1 : sitem
          })
          return subarray
        })

        const currentSequence = state.entities[id].sequence.map(function (item) {
          const subarray = item.map(function (sitem) {
            return sitem
          })
          return subarray
        })
        state.entities[id].sequenceComplete = JSON.stringify(convertedGoalSequence) == JSON.stringify(currentSequence)
        //const isCOmplete = JSON.stringify(convertedGoalSequence) == JSON.stringify(currentSequence)
        //console.log('Goal Complete ', isCOmplete)

        // const arr = state.entities[id].goalSequence.map(function (item) {
        //   //console.log('item ', item)
        //   //return item == 2 ? 1 : item
        //   const arr2 = item.map(function (sitem) {
        //     //console.log('sitem ', sitem)
        //     return sitem == 2 ? 1 : sitem
        //   })
        //   return arr2
        // })

        const majorKey = Key.majorKey(state.key)
        const majorKeyScale = majorKey.scale
        const minorKey = Key.minorKey(state.key)
        const minorKeyScale = minorKey.natural.scale
        const scale = state.scaleType === 'Major' ? majorKeyScale : minorKeyScale
        const isSingle = state.chordType === 'majsingle' || state.chordType === 'minsingle' ? true : false
        const chordTypeActual = state.chordType === 'majsingle' ? 'M' : state.chordType === 'minsingle' ? 'm' : state.chordType

        let steps = new Array(state.entities[id].groupSequence.length)
        for (let i = 0; i < state.entities[id].groupSequence.length; ++i) {
          //seqNoteCount[i] = groupSequence[i] > 0 ? (seqNoteCount[i] > 0 ? seqNoteCount[i] : 64) : seqNoteCount[i]
          steps[i] =
            state.entities[id].groupSequence[i] > 0
              ? isSingle
                ? Chord.getChord(
                    chordTypeActual,
                    `${scale[(state.entities[id].groupSequence[i] - 1) % 7]}${
                      state.entities[id].groupSequence[i] === 8 ? state.entities[id].octave + 1 : state.entities[id].octave
                    }`,
                  ).notes[0]
                : Chord.getChord(
                    chordTypeActual,
                    `${scale[(state.entities[id].groupSequence[i] - 1) % 7]}${
                      state.entities[id].groupSequence[i] === 8 ? state.entities[id].octave + 1 : state.entities[id].octave
                    }`,
                  ).notes
              : null
        }
        state.entities[id].steps = steps
        //console.log('steps = ', steps)
      }),
    )
  },
})
// const useMainStore = create(
//   devtools(
//     persist(mainStore, {
//       name: 'main',
//     }),
//   ),
// )
const useMainStore = create(
  devtools(mainStore, {
    name: 'main',
  }),
)

export default useMainStore
