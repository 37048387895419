import { Suspense } from 'react'
import * as THREE from 'three'
import { Canvas } from '@react-three/fiber'
import { Preload, OrbitControls, Sparkles, Float, Environment, Octahedron } from '@react-three/drei'
import T8x8 from '../../Erondon'
import T6x8 from '../../6x8'
import T16x4 from '../../16x4'
import { Box } from '@chakra-ui/react'
import { LayerMaterial, Depth, Noise } from 'lamina'
import { EffectComposer, DepthOfField, Bloom, Noise as Noise2, Vignette } from '@react-three/postprocessing'

const MainView = ({ scale = Array.from({ length: 50 }, () => 0.5 + Math.random() * 4) }) => {
  const deg2rad = (degrees) => degrees * (Math.PI / 180)
  // const { entities } = useMainStore()

  return (
    <>
      {/* <Button size={'xs'} colorScheme="pink" variant="outline" onClick={(e) => (e.target.blur(), dispatch(updateSequence(['derp', 'perp', [2], 2])))}>
        Update
      </Button> */}

      <Canvas dpr={[1, 2]} camera={{ zoom: 1.5 }}>
        {/* <Environment
          // Whether to affect scene.background
          files={'UW_1.hdr'}
          path={'/'}
        >
          <Depth colorA="#335533" colorB="#335588" alpha={0.999} mode="normal" near={0} far={300} origin={[100, 100, 100]} />
        </Environment> */}
        <Environment background>
          <mesh scale={100}>
            <sphereGeometry args={[1, 64, 64]} />
            <LayerMaterial side={THREE.BackSide}>
              <Depth colorA="blue" colorB="grey" alpha={1.0} mode="normal" near={0} far={300} origin={[100, 100, 100]} />
              <Noise mapping="local" type="cell" scale={0.75} mode="softlight" />
              {/* <Noise mapping="local" type="cell" scale={0.01} mode="subtract" /> */}
            </LayerMaterial>
          </mesh>
        </Environment>

        <Sparkles count={scale.length} size={scale} position={[0, 0.9, 0]} scale={[6, 6, 6]} speed={0.3} />
        {/* <pointLight intensity={5.1} position={[0, 2, 0]} />
        <directionalLight position={[1, 1, 5]} intensity={0.6} /> */}
        {/* <OrbitControls /> */}
        <Suspense fallback={null}>
          <Float
            speed={1} // Animation speed, defaults to 1
            rotationIntensity={1} // XYZ rotation intensity, defaults to 1
            floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
            // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
          >
            <Preload all />
            <T8x8 index={0} scale={[0.8, 0.8, 0.8]} position={[0, 1.2, 0]} rotation={[deg2rad(45), 0, deg2rad(0)]} />
            <T6x8 index={1} scale={[0.8, 0.8, 0.8]} position={[0, -1.8, 0]} rotation={[deg2rad(45), 0, deg2rad(0)]} />
            {/* <Octahedron rotation={[deg2rad(45), deg2rad(0), deg2rad(0)]}>
              <meshPhongMaterial color="#f3f3f3" wireframe />
            </Octahedron> */}
            <T16x4 index={2} scale={0.47} position={[0, -0.2, 0]} rotation={[deg2rad(45), deg2rad(0), deg2rad(0)]} />
          </Float>
        </Suspense>

        {/* <EffectComposer>
          <DepthOfField focusDistance={0} focalLength={0.02} bokehScale={2} height={700} />
          <Bloom luminanceThreshold={0} luminanceSmoothing={0.9} height={300} />
          <Noise2 opacity={0.02} />
          <Vignette eskil={false} offset={0.1} darkness={1.1} />
        </EffectComposer> */}
      </Canvas>
      {/* <Box style={{  }}>
        <h1>This</h1>
      </Box> */}
    </>
  )
}

export default MainView
