import * as React from 'react'
import { ChakraProvider, Box, Button, Text, Link, HStack, VStack, Grid, Flex, theme, Input } from '@chakra-ui/react'
import Peer from 'peerjs'
//import { v4 as uuidv4 } from "uuid";
import ShortUniqueId from 'short-unique-id'
import { useSelector, useDispatch } from 'react-redux'
import { ColorModeSwitcher } from './ColorModeSwitcher'
import MainView from './features/mainView/MainView'
import NavBar from './components/NavBar'
import PeerControl from './components/PeerControl'
import SoundControl from './components/SoundControl'

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Flex flexDirection={'column'}>
        {/* <Flex p={4}>
          <PeerControl />
        </Flex> */}
        <div
          style={{
            height: 'calc(100vh)',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              position: 'absolute',
              width: 'calc(100vw)',
              top: 0,
              left: 0,
              zIndex: 1,
            }}
          >
            <NavBar />
          </div>
          <SoundControl />
          <div
            style={{
              height: 'calc(100vh)',
              width: 'calc(100vw)',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 0,
            }}
          >
            <MainView />
          </div>
        </div>
      </Flex>
    </ChakraProvider>
  )
}
